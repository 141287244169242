import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurd implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const currentUser = this.auth.currentUserValue;
      if (currentUser) {
          // logged in so return true
          return true;
      }
    // if (this.auth.authenticated) {
    //   return true;
    // } else {
    //   this.router.navigateByUrl('/sessions/signin');
    // }
      this.router.navigate(['/sessions/signin'], { queryParams: { returnUrl: state.url } });
      return false;
  }
}
