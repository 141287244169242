import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataLayerService} from './data-layer.service';
import {BehaviorSubject} from 'rxjs';
import {WhiteLabelService} from './white-label.service';

@Injectable({
    providedIn: 'root'
})
export class OffersService {
    public offersList = [];
    public offersLoadedSubj: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    apiURL = '';

    constructor(private readonly dataLayer: DataLayerService, private wlService: WhiteLabelService) {
        // new BehaviorSubject<any>(JSON.parse(localStorage.getItem('kpmPartnersCurrentUser')));
        if (this.wlService.isKpm) {
            this.apiURL = 'https://partners.kpmbro.com/api/v1';
        }

        if (this.wlService.isSabia) {
            this.apiURL = 'https://partners.luns.io/api/v1';
        }

        if (window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')) {
            this.apiURL = 'http://localhost:8082/api/v1';
        }
    }

    getOffers() {
        console.log('im gone load offers from: ' + this.apiURL);
        // console.log('get offers called');
        if (this.offersList && this.offersList.length === 0) {
            this.dataLayer.get(this.apiURL + '/stats/offerList').subscribe(res => {
                this.offersList = res as [];
                this.offersLoadedSubj.next(res);
                return this.offersList;
            });
        } else {
            this.offersLoadedSubj.next(this.offersList);
            return this.offersList;
        }
    }
}
