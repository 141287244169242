import {Inject, Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class WhiteLabelService {

    public isKpm = false;
    public isSabia = false;
    logoSrc = '';
    themeColor: any;

    constructor(private titleService: Title, @Inject(DOCUMENT) private _document: HTMLDocument) {
        this.isKpm = window.location.href.includes('partners.kpmbro.com') || window.location.href.includes('127.0.0.1');
        this.isSabia = window.location.href.includes('partners.luns.io') || window.location.href.includes('localhost');
        if (this.isKpm) {
            this.titleService.setTitle('KPMBro Partners');
            this.logoSrc = 'assets/images/logo.png';
            this._document.getElementById('appFavicon').setAttribute('href', 'kpm-favicon.ico');
            this.themeColor = '#1a3f5d';
        } else if (this.isSabia) {
            this.titleService.setTitle('Luns Partners');
            this.logoSrc = 'assets/images/lunslogo.png';
            this._document.getElementById('appFavicon').setAttribute('href', 'luns-favicon.ico');
            this.themeColor = '#08b0ba';
        }

    }
}
