import {Component} from '@angular/core';
import {AuthService} from '../../services/auth.service';

@Component({
    selector: 'app-user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: []
})
export class UserAvatarComponent  {
    name = '';

    constructor(private auth: AuthService) {
        this.name = this.auth.currentUserData.name.substr(0, 14);
    }
}
