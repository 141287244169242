import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BtnLoadingComponent} from './btn-loading/btn-loading.component';
import {FeatherIconComponent} from './feather-icon/feather-icon.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {SharedPipesModule} from '../pipes/shared-pipes.module';
import {SharedDirectivesModule} from '../directives/shared-directives.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {LayoutsModule} from './layouts/layouts.module';
import {AppLoadingComponent} from './app-loading/app-loading.component';
import {GeoSelectDdComponent} from './geo-select-dd/geo-select-dd.component';
import {SelectDropDownModule} from 'ngx-select-dropdown';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserAvatarComponent} from './user-avatar/user-avatar.component';
import {AvatarModule} from 'ngx-avatar';
import {EditableComponent} from './inline-editor/editable.component';
import {ViewModeDirective} from './inline-editor/view-mode.directive';
import {EditModeDirective} from './inline-editor/edit-mode.directive';
import {FocusableDirective} from './inline-editor/focusable.directive';
import {EditableOnEnterDirective} from './inline-editor/editable-on-enter.directive';
import { NotificationCenterComponent } from './notification-center/notification-center.component';

const components = [
    BtnLoadingComponent,
    FeatherIconComponent,
    AppLoadingComponent,
    GeoSelectDdComponent,
    UserAvatarComponent,
    EditableComponent,
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedPipesModule,
        SharedDirectivesModule,
        PerfectScrollbarModule,
        NgbModule,
        SelectDropDownModule,
        FormsModule,
        AvatarModule,
        ReactiveFormsModule,
    ],
    declarations: [
        components,
        ViewModeDirective,
        EditModeDirective,
        FocusableDirective,
        EditableOnEnterDirective,
        NotificationCenterComponent
    ],
    exports: [
        components,
        ViewModeDirective,
        EditModeDirective,
        FocusableDirective,
        EditableOnEnterDirective,
        NotificationCenterComponent
    ]
})
export class SharedComponentsModule {
}
