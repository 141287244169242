import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NavigationService} from '../../../../services/navigation.service';
import {AuthService} from '../../../../services/auth.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AdminService} from '../../../../services/admin.service';
import {WhiteLabelService} from '../../../../services/white-label.service';

@Component({
    selector: 'app-header-sidebar-large',
    templateUrl: './header-sidebar-large.component.html',
    styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit, AfterViewInit {

    notifications: any[];
    userName = '';
    pageTitle = '';
    name: any;
    public adminOverRideName: string;
    logoSrc: string;
    themeColor: any;

    constructor(
        private wlService: WhiteLabelService,
        private navService: NavigationService,
        private auth: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private admin: AdminService,
    ) {
        this.logoSrc = wlService.logoSrc;
        this.themeColor = wlService.themeColor;
        this.name = this.auth.currentUserData.name.substr(0, 14);
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.pageTitle = this.getTitle(router.routerState, router.routerState.root).join('-');
            }
        });
    }

    getTitle(state, parent) {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            data.push(parent.snapshot.data.title);
        }

        if (state && parent) {
            data.push(...this.getTitle(state, state.firstChild(parent)));
        }
        return data;
    }

    ngOnInit() {
        this.userName = this.auth.currentUserData.name;
        if(this.admin.overRidePublisher !== ''){
            this.adminOverRideName = this.admin.overRidePublisher;
        }

    }

    ngAfterViewInit() {

    }

    toggelSidebar() {
        const state = this.navService.sidebarState;
        if (state.childnavOpen && state.sidenavOpen) {
            return state.childnavOpen = false;
        }
        if (!state.childnavOpen && state.sidenavOpen) {
            return state.sidenavOpen = false;
        }
        if (!state.sidenavOpen && !state.childnavOpen) {
            state.sidenavOpen = true;
            setTimeout(() => {
                state.childnavOpen = true;
            }, 50);
        }
    }

    signout() {
        this.auth.signout();
    }

    accountSettings() {
        this.router.navigate(['/pages/profile']);
    }

    changePublisher() {
        this.router.navigate(['sessions/admin']);
    }
}
