<div class="app-footer">
<!--	<div class="row">-->
<!--		<div class="col-md-9">-->
<!--			<p><strong>Gull - Angular Bootstrap Admin Dashboard Template</strong></p>-->
<!--			<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero-->
<!--					quis beatae officia saepe perferendis voluptatum minima eveniet voluptates dolorum,-->
<!--					temporibus nisi maxime-->
<!--					nesciunt totam repudiandae commodi sequi dolor quibusdam sunt.</p>-->
<!--		</div>-->
<!--	</div>-->
	<div class="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
<!--        <a class="btn btn-primary text-white btn-rounded mb-3 mb-sm-0 mr-0 mr-sm-3" -->
<!--        href="https://themeforest.net/item/gull-angular-bootstrap-admin-dashboard-template/22866096?ref=mh_rafi" -->
<!--        target="_blank">Buy Gull</a>-->
<!--        <a class="btn btn-warning btn-rounded mb-3 mb-sm-0" -->
<!--        href="https://goo.gl/forms/dwQGMJrnk4Q5XEBG3" -->
<!--        target="_blank">Feedback</a>-->
        
		<span class="flex-grow-1"></span>
		<div class="d-flex align-items-center">
			<img class="logo" [src]="this.logoSrc" alt="">
			<div>
				<p class="m-0">&copy; 2020 KPMBro</p>
				<p class="m-0">All rights reserved</p>
			</div>
		</div>
	</div>
</div>
