<!--<div id="loginBg">-->
<!--    <div class='white'>-->
<!--        <div class='squares'>-->
<!--            <div class='square'></div>-->
<!--            <div class='square'></div>-->
<!--            <div class='square'></div>-->
<!--            <div class='square'></div>-->
<!--            <div class='square'></div>-->
<!--            <div class='square'></div>-->
<!--            <div class='square'></div>-->
<!--            <div class='square'></div>-->
<!--            <div class='square'></div>-->
<!--            <div class='square'></div>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div class='grey'></div>-->
<!--</div>-->

<div class="auth-layout-wrap">
    <router-outlet></router-outlet>
</div>



