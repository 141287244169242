<div ngbDropdown [placement]="'bottom-right'">
    <div class="badge-top-container" *ngIf="notifications.length > 0">
        <span class="badge badge-danger newNotsBadge" *ngIf="NumberOfNew > 0">{{NumberOfNew}}</span>
        <i ngbDropdownToggle class="i-Bell text-muted header-icon" role="button"></i>
    </div>
    <div ngbDropdownMenu class="notification-dropdown rtl-ps-none" perfectScrollbar>
        <div class="dropdown-item d-flex" *ngFor="let item of notifications" (click)="messageClicked(messageModal, item)">
            <div class="notification-icon">
                <i class="{{item.icon}} text-{{item.status}} mr-1"></i>
            </div>
            <div class="notification-details flex-grow-1">
                <p class="m-0 d-flex align-items-center">
                    <span>{{item.title}}</span>
                    <span *ngIf="item.badge" class="badge badge-pill badge-{{item.status}} ml-1 mr-1">{{item.badge}}</span>
                    <span class="flex-grow-1"></span>
                    <span class="text-small text-muted ml-auto">{{item.time | relativeTime}}</span>
                </p>
                <p class="text-small text-muted m-0">{{item.short_text | excerpt:30}}</p>
                <div *ngIf="item.read == 0" class="newDot"></div>
                <div *ngIf="item.read != 0" class="oldDot"></div>
            </div>
        </div>
    </div>
</div>
<ng-template #messageModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title"><i class="{{selectedmsg.icon}} text-{{selectedmsg.status}} mr-1"></i> {{selectedmsg.title}}</h4>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [innerHTML]="selectedmsg.full_text">
    </div>
    <div class="modal-footer">
        {{selectedmsg.time | date: 'medium'}}
    </div>
</ng-template>
