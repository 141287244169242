import {Injectable} from '@angular/core';
import {LocalStoreService} from './local-store.service';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import {delay} from 'rxjs/operators';
import {DataLayerService} from './data-layer.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {WhiteLabelService} from './white-label.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    authenticated = false;
    userData;
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    apiURL = '';

    constructor(private store: LocalStoreService, private router: Router, private http: HttpClient, private wlService: WhiteLabelService) {
        this.checkAuth();

        if (this.wlService.isKpm) {
            this.apiURL = 'https://partners.kpmbro.com/api/v1';
        }

        if (this.wlService.isSabia) {
            this.apiURL = 'https://partners.luns.io/api/v1';
        }

        if (window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')) {
            this.apiURL = 'http://localhost:8082/api/v1';
        }

    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    public get currentUserData(): any {
        return JSON.parse(localStorage.getItem('kpmPartnersCurrentUser')) ? this.getDecodedAccessToken(JSON.parse(localStorage.getItem('kpmPartnersCurrentUser')).token) : null;
    }

    checkAuth() {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('kpmPartnersCurrentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        // this.userData = this.getDecodedAccessToken(res.token);
        // this.authenticated = this.store.getItem('demo_login_status');
    }

    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            // console.log('couldnt parse token');
            // console.log(Error);
            // console.log(token);
            return null;
        }
    }

    async signin(credentials) {

        return new Promise(async (resolve, reject) => {

            await this.http.post<any[]>(this.apiURL + '/auth/login', {
                'user': credentials.email,
                'password': credentials.password
            }).toPromise().then((res: any) => {
                // await this.dataLayer.doLogin(credentials.email, credentials.password).toPromise().then((res: any) => {
                if (res && res.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('kpmPartnersCurrentUser', JSON.stringify(res));
                    this.currentUserSubject.next((res));
                    this.userData = this.getDecodedAccessToken(res.token);
                    resolve(res);
                }
            }).catch(err => {
                reject(err);
            });
        });
    }

    async signup(data) {
        if (this.wlService.isKpm) {
            data.hash = 'extra1';
        }
        if (this.wlService.isSabia) {
            data.hash = 'luns';
        }
        return new Promise(async (resolve, reject) => {
            await this.http.post<any[]>(this.apiURL + '/auth/signup', data).toPromise().then((res: any) => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    }

    signout() {
        localStorage.clear();
        this.router.navigateByUrl('/sessions/signin');
    }
}
