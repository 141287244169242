import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Utils} from '../utils';
import {AuthService} from './auth.service';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {WhiteLabelService} from './white-label.service';

@Injectable({
    providedIn: 'root'
})
export class DataLayerService {

    private requestOptions = {};
    private apiURL;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private toastr: ToastrService,
        private router: Router,
        private wlService: WhiteLabelService
    ) {
        this.authService.currentUser.subscribe((res) => {
            if (res) {
                this.requestOptions = {
                    headers: {
                        'Authorization': `Bearer ` + res.token
                    },
                };
            }
        });

        if (this.wlService.isKpm) {
            this.apiURL = 'https://partners.kpmbro.com/api/v1';
        }

        if (this.wlService.isSabia) {
            this.apiURL = 'https://partners.luns.io/api/v1';
        }

        if (window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')) {
            this.apiURL = 'http://localhost:8082/api/v1';
        }

        // this.apiURL = window.location.href.includes('localhost') ? 'http://localhost:8082/api/v1' : 'https://partners.kpmbro.com/api/v1';
        // console.log(this.requestOptions);
    }

    // Error handling
    errorHandl(error, that, test) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        this.toastr.error(errorMessage, null, {
            'closeButton': false,
            'progressBar': true,
            'positionClass': 'toast-bottom-full-width',
            'timeOut': 5000,
            easeTime: 300,
            // 'easing': 'linear',
        });
        if (error.status === 401) {
            localStorage.clear();
            this.router.navigateByUrl('/sessions/signin');
        }
        return throwError(errorMessage);
    }

    doLogin(user, password) {
        return this.http.post<any[]>(this.apiURL + '/auth/login', {'user': user, 'password': password});
    }

    getPubOffersApplications() {
        if (this.authService.currentUserData.isAdmin) {
            return this.http.get<any[]>(this.apiURL + '/a/offers-applications', this.requestOptions).pipe(
                catchError(this.errorHandl.bind(this))
            );
        }
    }


    score(reqObj) {
        const link = this.apiURL + '/stats/score';
        return this.http.post<any[]>(link, reqObj, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    offersstats(reqObj) {
        const link = this.apiURL + '/stats/offers';
        return this.http.post<any[]>(link, reqObj, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    offersavailble() {
        const link = this.apiURL + '/stats/available-offers';
        return this.http.post<any[]>(link, null, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    sendWishList(array) {
        const link = this.apiURL + '/wishlist';
        return this.http.post<any[]>(link, array, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    getPendingWishLists() {
        return this.http.get<any[]>(this.apiURL + '/wishlist', this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    getPushOffers() {
        return this.http.get<any[]>(this.apiURL + '/push-offers', this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    getNotifications() {
        return this.http.get<any[]>(this.apiURL + '/notifications', this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    sendReadRecieptMsg(offerId) {
        const link = this.apiURL + '/notifications/read/' + offerId;
        return this.http.post<any[]>(link, {}, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    getAdminWishesList() {
        return this.http.get<any[]>(this.apiURL + '/a/wish-lists', this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }


    stats(date, groupBy, selectedCountryForWhere, offerIdForWhere) {

        const requestObj = {
            where: [],
            groupBy: [],
            dates: ''
        };

        const link = this.apiURL + '/stats/main';
        // let where = '';
        if (selectedCountryForWhere) {
            requestObj.where.push({'geo': selectedCountryForWhere});
        }

        // where += selectedCountryForWhere;
        if (offerIdForWhere && offerIdForWhere.length > 0) {
            //     where += '|';
            const ids = offerIdForWhere.map(item => item.Publisher_data_os).join(',');
            requestObj.where.push({'offerIds': ids});
        }

        requestObj.groupBy.push(groupBy);

        requestObj.dates = date;

        // if (offerIdForWhere) {
        //     where += '&where=' + offerIdForWhere;
        // }

        // if (where !== '') {
        //     link += where;
        // }
        return this.http.post<any[]>(link, requestObj, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    getDashboard(timeSpan) {
        // alert('test');
        // console.log('going to get dash with req options');
        // console.log(localStorage);
        return this.http.get<any[]>(this.apiURL + '/dashboard/' + timeSpan, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    getHotOffers() {
        return this.http.get<any[]>(this.apiURL + '/hotoffers', this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    applyForOffer(offer, reason) {
        offer.reason = reason;
        const link = this.apiURL + '/hotoffers/apply';
        return this.http.post<any[]>(link, offer, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    applyForAvailableOffer(offer) {
        const link = this.apiURL + '/stats/available-offers/apply';
        return this.http.post<any[]>(link, offer, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    getPublishers() {
        // console.log(localStorage);
        return this.http.get<any[]>(this.apiURL + '/publishers', this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    ologin(ppid, ppgid, uuid, phash) {
        const link = this.apiURL + '/auth/aod';
        return this.http.post<any[]>(link, {pid: ppid, pgid: ppgid, uid: uuid, uphash: phash}, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    updateBilling(data) {
        const link = this.apiURL + '/billing';
        return this.http.post<any[]>(link, data, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    updatePublisherBillingData(data) {
        const link = this.apiURL + '/billing/fields';
        return this.http.post<any[]>(link, data, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }


    savePostBack(postbackLink: any) {
        const link = this.apiURL + '/publisher/postback';
        return this.http.post<any[]>(link, {link: postbackLink}, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    getBilling() {
        const link = this.apiURL + '/billing';
        return this.http.get<any[]>(link, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }


    getPublisher() {
        const link = this.apiURL + '/publisher';
        return this.http.get<any[]>(link, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    getApiKey() {
        const link = this.apiURL + '/apiKey';
        return this.http.get<any[]>(link, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    getPostback() {
        const link = this.apiURL + '/postback';
        return this.http.get<any[]>(link, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    get(url) {
        return this.http.get<any[]>(url, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    rejectPubAppApplication(value: any) {
        const link = this.apiURL + '/a/offers-applications/' + value + '/r';
        return this.http.get<any[]>(link, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    approvePubAppApplication(value: any) {
        const link = this.apiURL + '/a/offers-applications/' + value + '/a';
        return this.http.get<any[]>(link, this.requestOptions).pipe(
            catchError(this.errorHandl.bind(this))
        );
    }

    getInvoices() {
        return this.http.get<any[]>('/api/invoices');
    }

    getInvoice(id) {
        return this.http.get<any[]>('/api/invoices/' + id);
    }

    saveInvoice(invoice) {
        if (invoice.id) {
            return this.http.put<any[]>('/api/invoices/' + invoice.id, invoice);
        } else {
            invoice.id = Utils.genId();
            return this.http.post<any[]>('/api/invoices/', invoice);
        }
    }

    deleteInvoice(id) {
        return this.http.delete<any[]>('/api/invoices/' + id);
    }

    getMails() {
        return this.http.get<any[]>('/api/mails');
    }

    getCountries() {
        return this.http.get<any[]>('/api/countries');
    }

    getProducts() {
        return this.http.get<any[]>('api/products');
    }


}
