import {Component, OnInit, Input} from '@angular/core';
import {AppLoadingService} from '../../services/app-loading.service';

@Component({
    selector: 'app-loading',
    templateUrl: './app-loading.component.html',
    styleUrls: ['./app-loading.component.scss']
})
export class AppLoadingComponent implements OnInit {

    // public hide = false;
    // public remove = false;

    constructor(private readonly appLoad: AppLoadingService) {
    }

    ngOnInit() {
        // setTimeout(() => {
        //     this.hide = true;
        //     setTimeout(() => {
        //         this.remove = true;
        //     }, 501);
        // }, 3000);
    }

}
