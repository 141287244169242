import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {InMemoryWebApiModule} from 'angular-in-memory-web-api';
import {InMemoryDataService} from './shared/inmemory-db/inmemory-db.service';
import {HttpClientModule} from '@angular/common/http';
import {SharedComponentsModule} from './shared/components/shared-components.module';
import {ToastrService} from 'ngx-toastr';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {OffersService} from './shared/services/offers.service';
import {MatInputModule} from '@angular/material/input';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AdminService} from './shared/services/admin.service';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {LivechatWidgetModule} from '@livechat/angular-widget';
import {WhiteLabelService} from './shared/services/white-label.service';
import {PubSubService} from './shared/services/angular7-pubsub.service';
import {AppStoreScrapperService} from './shared/services/appStoreScrapper.service';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        SharedModule,
        HttpClientModule,
        BrowserAnimationsModule,
        InMemoryWebApiModule.forRoot(InMemoryDataService, {passThruUnknownUrl: true}),
        AppRoutingModule,
        SharedComponentsModule,
        NgxDaterangepickerMd.forRoot(),
        MatInputModule,
        FlexLayoutModule,
        NgxGoogleAnalyticsModule.forRoot('UA-161959096-1'),
        NgxGoogleAnalyticsRouterModule,
        LivechatWidgetModule,
    ],
    providers: [ToastrService, OffersService, AdminService, WhiteLabelService, PubSubService, AppStoreScrapperService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
