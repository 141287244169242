import {Component, OnInit} from '@angular/core';
import {DataLayerService} from '../../services/data-layer.service';
import {PubSubService} from '../../services/angular7-pubsub.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-notification-center',
    templateUrl: './notification-center.component.html',
    styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnInit {
    notifications = [];
    NumberOfNew = 0;
    selectedmsg: any;

    constructor(private dataLayerService: DataLayerService, private pubsub: PubSubService, private modalService: NgbModal) {
    }

    ngOnInit(): void {

        this.pubsub.$sub('loadMessages').subscribe((from) => {
            this.loadMessages();
        });

        this.loadMessages();
        const that = this;
        setInterval(() => {
            that.loadMessages();
        }, 60000);
        // this.notifications = [
        //     {
        //         icon: 'i-Speach-Bubble-6',
        //         title: 'Update Billing Details',
        //         short_text: 'Go Review Billing',
        //         full_text: 'Go Review Billing',
        //         time: new Date(),
        //         status: 'primary',
        //         link: '/pages/profile/billing',
        //         read: false,
        //     },
        //     {
        //         icon: 'i-Speach-Bubble-6',
        //         title: 'New message',
        //         badge: '3',
        //         short_text: 'James: Hey! are you busy?',
        //         full_text: 'James: Hey! are you busy?',
        //         time: new Date(),
        //         status: 'primary',
        //         link: '/chat',
        //         read: false,
        //     },
        //     {
        //         icon: 'i-Receipt-3',
        //         title: 'New order received',
        //         badge: '$4036',
        //         short_text: '1 Headphone, 3 iPhone x',
        //         full_text: '1 Headphone, 3 iPhone x',
        //         time: new Date('11/11/2018'),
        //         status: 'success',
        //         link: '/tables/full',
        //         read: false,
        //     },
        //     {
        //         icon: 'i-Empty-Box',
        //         title: 'Product out of stock',
        //         short_text: 'Headphone E67, R98, XL90, Q77',
        //         full_text: 'Headphone E67, R98, XL90, Q77',
        //         time: new Date('11/10/2018'),
        //         status: 'danger',
        //         link: '/tables/list',
        //         read: true,
        //     },
        //     {
        //         icon: 'i-Data-Power',
        //         title: 'Server up!',
        //         short_text: 'Server rebooted successfully',
        //         full_text: 'Server rebooted successfully',
        //         time: new Date('11/08/2018'),
        //         status: 'success',
        //         link: '/dashboard/v2',
        //         read: true,
        //     },
        //     {
        //         icon: 'i-Data-Block',
        //         title: 'Server down!',
        //         badge: 'Resolved',
        //         short_text: 'Region 1: Server crashed!',
        //         full_text: 'Region 1: Server crashed!',
        //         time: new Date('11/06/2018'),
        //         status: 'danger',
        //         link: '/dashboard/v3',
        //         read: true,
        //     }
        // ];

    }

    loadMessages() {
        this.dataLayerService.getNotifications().subscribe(res => {
            this.notifications = res;
            let counter = 0;
            for (let i = 0; i < this.notifications.length; i++) {
                if (this.notifications[i].read === 0) {
                    counter++;
                }
            }
            this.NumberOfNew = counter;
        });
    }

    messageClicked(content, item) {
        this.selectedmsg = item;
        const modalRef = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true})
            .result.then((result) => {
            }, (reason) => {
                this.messageRead(item.id);
            });
    }

    messageRead(offerId) {
        this.dataLayerService.sendReadRecieptMsg(offerId).subscribe(res => {
            if (res.Message === 'ok') {
                this.loadMessages();
            }
        });
    }
}
