import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppLoadingService {

    public hide = false;
    public remove = true;

    hideMe() {

    }
}
