import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-geo-select-dd',
    templateUrl: './geo-select-dd.component.html',
    styleUrls: []
})
export class GeoSelectDdComponent  {

    @Output() public onValueChange: EventEmitter<any> = new EventEmitter();
    public geoDdConfig = {
        search: true,
        height: '200px',
        placeholder: 'Geo',
        searchPlaceholder: 'Geo',
        displayKey: 'name',
    };
    public countryList = [{'name': 'USA', 'value': 'us'},
        {'name': 'Canada', 'value': 'ca'},
        {'name': 'United Kingdom', 'value': 'gb'},
        {'name': 'Germany', 'value': 'de'},
        {'name': 'France', 'value': 'fr'},
        {'name': 'Spain', 'value': 'es'},
        {'name': 'Italy', 'value': 'it'},
        {'name': 'Sweden', 'value': 'se'},
        {'name': 'Greece', 'value': 'gr'},
        {'name': 'Portugal', 'value': 'pt'},
        {'name': 'Netherlands', 'value': 'nl'},
        {'name': 'Belgium', 'value': 'be'},
        {'name': 'Russia', 'value': 'ru'},
        {'name': 'Ukraine', 'value': 'ua'},
        {'name': 'Poland', 'value': 'pl'},
        {'name': 'Israel', 'value': 'il'},
        {'name': 'Turkey', 'value': 'tr'},
        {'name': 'Australia', 'value': 'au'},
        {'name': 'Malaysia', 'value': 'my'},
        {'name': 'Thailand', 'value': 'th'},
        {'name': 'South Korea', 'value': 'kr'},
        {'name': 'Japan', 'value': 'jp'},
        {'name': 'Philippines', 'value': 'ph'},
        {'name': 'Singapore', 'value': 'sg'},
        {'name': 'China', 'value': 'cn'},
        {'name': 'Hong Kong', 'value': 'hk'},
        {'name': 'Taiwan', 'value': 'tw'},
        {'name': 'India', 'value': 'in'},
        {'name': 'Pakistan', 'value': 'pk'},
        {'name': 'Iran', 'value': 'ir'},
        {'name': 'Indonesia', 'value': 'id'},
        {'name': 'Azerbaijan', 'value': 'az'},
        {'name': 'Kazakhstan', 'value': 'kz'},
        {'name': 'UAE', 'value': 'ae'},
        {'name': 'Mexico', 'value': 'mx'},
        {'name': 'Brazil', 'value': 'br'},
        {'name': 'Argentina', 'value': 'ar'},
        {'name': 'Chile', 'value': 'cl'},
        {'name': 'Peru', 'value': 'pe'},
        {'name': 'Ecuador', 'value': 'ec'},
        {'name': 'Colombia', 'value': 'co'},
        {'name': 'South Africa', 'value': 'za'},
        {'name': 'Egypt', 'value': 'eg'},
        {'name': 'Denmark', 'value': 'dk'},
        {'name': 'Angola', 'value': 'ao'},
        {'name': 'Cameroon', 'value': 'cm'},
        {'name': 'Central African Republic', 'value': 'cf'},
        {'name': 'Chad', 'value': 'td'},
        {'name': 'Benin', 'value': 'bj'},
        {'name': 'Ethiopia', 'value': 'et'},
        {'name': 'Djibouti', 'value': 'dj'},
        {'name': 'Gambia', 'value': 'gm'},
        {'name': 'Ghana', 'value': 'gh'},
        {'name': 'Côte d\'Ivoire', 'value': 'ci'},
        {'name': 'Kenya', 'value': 'ke'},
        {'name': 'Liberia', 'value': 'lr'},
        {'name': 'Madagascar', 'value': 'mg'},
        {'name': 'Mali', 'value': 'ml'},
        {'name': 'Mauritania', 'value': 'mr'},
        {'name': 'Mauritius', 'value': 'mu'},
        {'name': 'Morocco', 'value': 'ma'},
        {'name': 'Mozambique', 'value': 'mz'},
        {'name': 'Nigeria', 'value': 'ng'},
        {'name': 'Senegal', 'value': 'sn'},
        {'name': 'Seychelles', 'value': 'sc'},
        {'name': 'Zimbabwe', 'value': 'zw'},
        {'name': 'South Sudan', 'value': 'ss'},
        {'name': 'Sudan', 'value': 'sd'},
        {'name': 'Togo', 'value': 'tg'},
        {'name': 'Tunisia', 'value': 'tn'},
        {'name': 'Uganda', 'value': 'ug'},
        {'name': 'Zambia', 'value': 'zm'},
        {'name': 'Afghanistan', 'value': 'af'},
        {'name': 'Bahrain', 'value': 'bh'},
        {'name': 'Bangladesh', 'value': 'bd'},
        {'name': 'Armenia', 'value': 'am'},
        {'name': 'Bhutan', 'value': 'bt'},
        {'name': 'Myanmar', 'value': 'mm'},
        {'name': 'Cambodia', 'value': 'kh'},
        {'name': 'Georgia', 'value': 'ge'},
        {'name': 'Iraq', 'value': 'iq'},
        {'name': 'Jordan', 'value': 'jo'},
        {'name': 'Lebanon', 'value': 'lb'},
        {'name': 'Maldives', 'value': 'mv'},
        {'name': 'Mongolia', 'value': 'mn'},
        {'name': 'Oman', 'value': 'om'},
        {'name': 'Qatar', 'value': 'qa'},
        {'name': 'Saudi Arabia', 'value': 'sa'},
        {'name': 'Vietnam', 'value': 'vn'},
        {'name': 'Turkmenistan', 'value': 'tm'},
        {'name': 'Uzbekistan', 'value': 'uz'},
        {'name': 'Yemen', 'value': 'ye'},
        {'name': 'Albania', 'value': 'al'},
        {'name': 'Andorra', 'value': 'ad'},
        {'name': 'Austria', 'value': 'at'},
        {'name': 'Bosnia and Herzegovina', 'value': 'ba'},
        {'name': 'Bulgaria', 'value': 'bg'},
        {'name': 'Belarus', 'value': 'by'},
        {'name': 'Croatia', 'value': 'hr'},
        {'name': 'Cyprus', 'value': 'cy'},
        {'name': 'Czech Republic', 'value': 'cz'},
        {'name': 'Denmark', 'value': 'dk'},
        {'name': 'Estonia', 'value': 'ee'},
        {'name': 'Finland', 'value': 'fi'},
        {'name': 'Hungary', 'value': 'hu'},
        {'name': 'Iceland', 'value': 'is'},
        {'name': 'Ireland', 'value': 'ie'},
        {'name': 'Latvia', 'value': 'lv'},
        {'name': 'Liechtenstein', 'value': 'li'},
        {'name': 'Lithuania', 'value': 'lt'},
        {'name': 'Luxembourg', 'value': 'lu'},
        {'name': 'Malta', 'value': 'mt'},
        {'name': 'Monaco', 'value': 'mc'},
        {'name': 'Moldova', 'value': 'md'},
        {'name': 'Montenegro', 'value': 'me'},
        {'name': 'Norway', 'value': 'no'},
        {'name': 'Romania', 'value': 'ro'},
        {'name': 'Serbia', 'value': 'rs'},
        {'name': 'Slovakia', 'value': 'sk'},
        {'name': 'Slovenia', 'value': 'si'},
        {'name': 'Switzerland', 'value': 'ch'},
        {'name': 'Macedonia', 'value': 'mk'},
        {'name': 'Bahamas', 'value': 'bs'},
        {'name': 'Belize', 'value': 'bz'},
        {'name': 'British Virgin Islands', 'value': 'vg'},
        {'name': 'Costa Rica', 'value': 'cr'},
        {'name': 'Cuba', 'value': 'cu'},
        {'name': 'Dominica', 'value': 'dm'},
        {'name': 'Haiti', 'value': 'ht'},
        {'name': 'Honduras', 'value': 'hn'},
        {'name': 'Jamaica', 'value': 'jm'},
        {'name': 'Aruba', 'value': 'aw'},
        {'name': 'Panama', 'value': 'pa'},
        {'name': 'Puerto Rico', 'value': 'pr'},
        {'name': 'Trinidad and Tobago', 'value': 'tt'},
        {'name': 'Fiji', 'value': 'fj'},
        {'name': 'New Zealand', 'value': 'nz'},
        {'name': 'Bolivia', 'value': 'bo'},
        {'name': 'Paraguay', 'value': 'py'},
        {'name': 'Uruguay', 'value': 'uy'},
        {'name': 'Venezuela', 'value': 've'}];
    ddValChanged(e) {
        this.onValueChange.emit(e);
    }
}
