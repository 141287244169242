import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AdminService {
    public get overRidePublisher(): any {
        return localStorage.getItem('currentPublisher');
    }
}
