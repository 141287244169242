import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AuthService} from './auth.service';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}

export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    defaultMenu: IMenuItem[] = [
        {
            name: 'Dashboard',
            description: 'Dashboards',
            type: 'link',
            icon: 'i-Dashboard',
            state: '/dashboard'
        },
        {
            name: 'Stats',
            description: 'Stats Report',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/tables/stats'
        },
        {
            name: 'Score',
            description: 'Score Report',
            type: 'link',
            icon: 'i-Bar-Chart-4',
            state: '/tables/score'
        },
        {
            name: 'Offers',
            description: 'Offers Report',
            type: 'dropDown',
            icon: 'i-Shop',
            state: '/tables/offers',
            sub: [
                {icon: 'i-Shop', name: 'Live Offers', state: '/tables/offers', type: 'link'},
                {icon: 'i-Shop', name: 'Available Offers', state: '/tables/offers-available', type: 'link'},
            ],
        },
        {
            name: 'Documentation',
            description: 'Documentation',
            type: 'dropDown',
            icon: 'i-Files',
            sub: [
                {icon: 'i-Shop', name: 'API', state: '/documentation/api', type: 'link'},
            ],
            state: '/documentation/api'
        }
        // {
        //     name: 'Reports',
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //     type: 'dropDown',
        //     icon: 'i-File-Horizontal-Text',
        //     sub: [
        //         { icon: 'i-Shop', name: 'Offers', state: '/tables/filter', type: 'link' },
        //     ]
        // },
    ];


    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };

    constructor(private authService: AuthService) {
        // console.log(this.authService.currentUserData);
        if (authService.currentUserData.isAdmin) {
            this.defaultMenu.push({
                name: 'Admin',
                description: 'Offers Report',
                type: 'dropDown',
                icon: 'i-Administrator',
                sub: [
                    {icon: 'i-Shop', name: 'Offers Applications', state: '/a/offer-applications', type: 'link'},
                    {icon: 'i-Shop', name: 'Wish List Requests', state: '/a/wish-lists', type: 'link'},
                ],
                state: '/a/offer-applications'
            });
        }
    }


    // You can customize this method to supply different menu for
    // different user type.
    // publishNavigationChange(menuType: string) {
    //   switch (userType) {
    //     case 'admin':
    //       this.menuItems.next(this.adminMenu);
    //       break;
    //     case 'user':
    //       this.menuItems.next(this.userMenu);
    //       break;
    //     default:
    //       this.menuItems.next(this.defaultMenu);
    //   }
    // }
}
