import { Component, OnInit } from '@angular/core';
import {WhiteLabelService} from '../../services/white-label.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  logoSrc;
  constructor(private wlService: WhiteLabelService) {
    this.logoSrc = wlService.logoSrc;
  }

  ngOnInit() {
  }

}
