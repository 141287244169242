import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})


export class AppStoreScrapperService {

    MAPPINGS = {
        // FIXME add appId

        title: ['ds:5', 0, 0, 0],
        description: {
            path: ['ds:5', 0, 10, 0, 1],
            fun: this.descriptionText
        },
        descriptionHTML: ['ds:5', 0, 10, 0, 1],
        summary: ['ds:5', 0, 10, 1, 1],
        icon: ['ds:5', 0, 12, 1, 3, 2],
    };

    constructor(private http: HttpClient) {

    }

    descriptionText(description) {
        console.log(description);
        // preserve the line breaks when converting to text
        // const html = cheerio.load('<div>' + description.replace(/<br>/g, '\r\n') + '</div>');
        // return cheerio.text(html('div'));
    }

    async scrape(link) {

        class ScrapeResult {
            title: string;
            icon: string;
            description: string;
        }

        // link = 'https://apps.apple.com/au/app/etoro-trading-social/id674984916?uo=4';
        link = 'https://play.google.com/store/apps/details?id=com.delivery.korea.client&gl=kr&hl=en_US';

        const parsedLink = this.parseURL(link);
        const ios = link.includes('apple');
        const google = link.includes('google');
        // console.log(link);
        // console.log(parsedLink);

        if (ios) {
            const appId = await parsedLink.queryParams.filter((value) => {
                return value.includes('id');
            });
            let geo = 'us';
            if (parsedLink.queryParams[1].length === 2) {
                geo = parsedLink.queryParams[1];
            }
            const id = appId[0].split('id')[1];
            const appResponse: any = await this.scrapeIos(id, geo).toPromise();
            if (appResponse.resultCount > 0) {
                const appData = appResponse.results[0];
                const result = new ScrapeResult();
                result.description = appData.description;
                result.title = appData.trackName;
                result.icon = appData.artworkUrl100;
                return result;
            }
        }

        if (google) {
            // const res = await this.scrapeAndroid('com.delivery.korea.client').toPromise();
            // const test = this.parse(res);
            // console.log(test);
        }
    }

    scrapeIos(id, geo) {
        const url = 'https://itunes.apple.com/lookup?id=' + id + '&country=' + geo + '&entity=software';
        return this.http.get<any[]>(url).pipe(

        );
    }

    scrapeAndroid(id) {

        const PLAYSTORE_URL = 'https://play.google.com/store/apps/details';
        const lang = 'en';
        const country = 'us';
        const reqUrl = `${PLAYSTORE_URL}?id=${id}&hl=${lang}&gl=${country}`;


        const xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener('readystatechange', function() {
            if (this.readyState === 4) {
                console.log(this.responseText);
            }
        });

        xhr.open('GET', 'https://play.google.com/store/apps/details?id=com.delivery.korea.client&gl=kr&hl=en_US');

        xhr.send();

        return this.http.get<any[]>(reqUrl, {
            headers: {
                'Access-Control-Allow-Origin': `*`
            },
        }).pipe(
        );

    }

    parseURL(url) {
        const parser = document.createElement('a');
        const searchObject = {};
        let queries, split, i;
        // Let the browser do the work
        parser.href = url;
        // Convert query string to object
        queries = parser.search.replace(/^\?/, '').split('&');
        for (i = 0; i < queries.length; i++) {
            split = queries[i].split('=');
            searchObject[split[0]] = split[1];
        }
        return {
            protocol: parser.protocol,
            host: parser.host,
            hostname: parser.hostname,
            port: parser.port,
            pathname: parser.pathname,
            search: parser.search,
            searchObject: searchObject,
            hash: parser.hash,
            queryParams: parser.pathname.split('/')
        };
    }
}
